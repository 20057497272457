import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeViewNew.vue'),
  },
  {
    path: '/tokenapproval/:chain/:address',
    name: 'TokenApproval',
    component: () => import('../views/TokenApproval.vue'),
  },
  {
    path: '/data',
    name: 'Data',
    component: () => import('../views/DataView.vue'),
  },
  {
    path: '/security',
    name: 'Security',
    component: () => import('../views/SecurityView.vue'),
  },
  {
    path: '/token/:chain/:address',
    name: 'Token',
    component: () => import('../views/TokenView.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
